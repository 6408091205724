import React from 'react'
import Img from 'gatsby-image'

import {
    GalleryAlbum,
    GalleryElement,
} from './GalleryComponents'


export default function Gallery({images}) {
    
    return (
        <GalleryAlbum style={{maxWidth:1200}}>
            {
                images()?.map( (el,index) => 
                    (
                        (
                            
                                <GalleryElement target="_blank" href={el.src} key={`album__${index}`}
                                    data-sal="flip-up"
                                    data-sal-delay="800"
                                    data-sal-easing="linear"
                                >
                                    
                                    <Img 
                                        fluid = {el} 
                                        alt ='UPVC Image'
                                    />
                                </GalleryElement>
                      
                            
                          )
                    )    
                )
            }
        </GalleryAlbum>
    )
}
