import styled from "styled-components";
import { colors } from "../../theme";



export const GalleryFilter = styled.div`
    width: 100%;
    background-color:${colors.green};
    color: white;
    font-weight: 900;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 20px;
    padding-left: 10vw;
    position: sticky;
    top: 80px;
    z-index: 1;
    overflow-x: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    ::-webkit-scrollbar {
        display: none;
}

`

export const GalleryFilterElement = styled.p`
    margin:0px 60px;
    cursor:pointer;
    opacity: ${({active}) => active ? 1 : .4};
    transition: opacity .2s ease-in;
    &:hover{
        opacity: 1;
    }
`

export const GalleryAlbum = styled.div`
    display:grid;
    grid-gap: 10px;
    grid-template-columns:  repeat(auto-fill,minmax(350px,1fr));
    grid-auto-rows:minxmax(50px, auto);
    width: 90%;
    margin: 50px auto;
    padding: 0 32px;
    img{
        transition:transform 0.6s ease !important;
    }
    img:hover{
        transform:scale(1.1);
    }
  
    @media screen and (max-width: 400px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
`

export const GalleryElement = styled.a`
    display: flex;
    flex-direction: column;
    &:nth-child(1){
        grid-column-end: span 2;
        grid-row-end: span 2;
    }
    div{
        height:100%;
    }
    @media screen and (max-width: 400px) {
        width: 200px;
    }

`

