import React , { useState } from "react"
import { graphql } from "gatsby"
import '../styles/global.css'
import {
    GalleryFilter,
    GalleryFilterElement
} from "../components/Gallery/GalleryComponents"
import Gallery from "../components/Gallery"
import {
    AboutStyle,
    AboutContainer,
    Title,
    PointerSvg
} from '../components/About/AboutElements'
import { findFromImages } from "."
import { texts } from "../texts"
import { GlobalContext } from "../components/GlobalContext"
import Layout from "../layout"

function shuffle(array) {
  let currentIndex = array.length,  randomIndex;

  // While there remain elements to shuffle...
  while (currentIndex !== 0) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }

  return array;
}

const GalleryPage = ({data,location}) => {
  const { language } = React.useContext(GlobalContext)

  const [filter,setFilter] = useState(() => location?.state?.path ? location?.state?.path : 'all')

  return (
    <>
            <AboutStyle style={{marginTop:100}}>
                    <AboutContainer>
                        <Title>
                                <PointerSvg/>
                                <h1 style={{marginLeft:10}}>{texts.gallery.title[language]}</h1>
                        </Title>
                    </AboutContainer>
            </AboutStyle>
            <div 
              style={{position:'relative'}}
    
            >
            <GalleryFilter>
                {
                    texts.gallery.filters.map( (el,index) =>
                        (<GalleryFilterElement active={filter === el.name} onClick={() => setFilter(el.name)} key={`${index}_${el.name}`} >{el[language]}</GalleryFilterElement>)
                    )
                }
            </GalleryFilter>
              <Gallery 
                images = {
                  () => {
                    let elements 
                    if(filter === 'all') {
                      const doors = findFromImages('door',data,false)
                      const windows = findFromImages('image',data,false)
                      elements =  [...shuffle(windows),...shuffle(doors)]
                    }else{
                      elements = findFromImages(filter,data,false)
                    }
                    return [...(elements)]
                  }
                }  
              />
            </div>
    </>
        
   
  )
}


export const query = graphql`
    query SecondQuery {
        allFile(filter: {extension: {regex: "/(jpg)|(jpeg)|(png)/"}}) {
          edges {
              node {
              id
              name
              childImageSharp {
                  fluid(maxWidth: 1000){
                  ...GatsbyImageSharpFluid
                  }
              }
              }
          }
        }
    }
`
GalleryPage.Layout = Layout

export default GalleryPage
